.select {
  display: inline-block;

  p {
    font-size: 17px;
    color: $white;
    display: inline-block;
  }
}
.scopeItem {
  color: $red-orange;
  cursor: pointer;
}
.scopeItem:hover {
  color: $white;
}
@media (max-width: 550px) {
  .select {
    width: 100%;

    p {
      width: 100%;
      margin-bottom: 10px;
    }

    > div {
      width: 100%;
      margin: 0 0 28px;

      .el-cascader {
        width: 100%;
      }
    }

    &:nth-child(1) > div {
      @include rtl-sass-prop(margin-right, margin-left, 0);
    }
  }
}
