@import '@/assets/css/components/select.scss';
// .referralLinks_content {
//   .link {
//     min-height: 100px;
//     box-shadow: inset 0px 0px 5px 2px $tropical-green;
//     p{
//       word-break: break-all;
//     }
//     &:hover {
//       background-color: $tropical-green;
//     }
//   }
// }
.query {
  display: flex;
  justify-content: center;
  @include screen-mobile() {
    flex-direction: column;
  }
  .select {
    font: normal normal 300 20px/24px Proxima Nova;
    width: 35%;
    display: flex;
    justify-content: center;

    & > div {
      margin: 0;
    }
    p {
      text-align: center;
      margin-bottom: 10px;
    }
  }
  .short_link_but {
    align-self: flex-end;
  }
}
.referralLinks_content {
  margin-top: 40px;
}
.link_container {
  flex-wrap: wrap;
  background: $theme-hue;

  li {
    padding: 35px;
    box-sizing: border-box;
    border-radius: 0 !important;
    border-bottom: 1px $light-white solid;
    @include screen-mobile() {
      flex-direction: column;
    }
    &:last-child {
      border: none;
    }
    span {
      font: normal normal 600 16px/23px Proxima Nova;
    }
    p {
      font: normal normal 300 20px/23px Proxima Nova;
      color: $white;
      margin-top: 7px;
      overflow: hidden;
    }
    button {
      padding: 15px 30px;
      border: 2px solid $white;
      background: none;
      color: $white;
      max-height: 53px;
      flex-shrink: 0;
      @include screen-mobile() {
        margin-top: 20px;
      }
    }
  }
}
/deep/ .el-dialog {
  background-color: #00404d;
  .el-form-item__label {
    color: #fff;
  }
  .el-dialog__headerbtn {
    right: 15px;
    .el-dialog__close {
      color: #ffffff;
    }
    .el-dialog__close:hover {
      color: #ccc;
    }
  }
  .el-dialog__body {
    padding: 0px 45px 20px;
    color: white;
  }
}
/deep/.first_input {
  margin-top: 30px;
  div {
    font-size: 24px;
    color: #7f9399;
    font-style: normal;
    margin-top: -5px;
  }
  .el-form-item__content {
    .el-input {
      .el-input__inner {
        color: #fff;
        border: 0px;
      }
    }
  }
}
/deep/.second_input {
  .el-form-item__content {
    div:first-child {
      border: 0 !important;
    }
    .el-input {
      border-radius: 4px;
      .el-input__inner {
        color: #fff;
        background: rgba(255, 255, 255, 0.04);
        border: 0;
        border: 1px solid #466066 !important;
        outline: none;
        padding-left: 10px;
        font-size: 24px;
        box-sizing: border-box;
        width: 438px;
        height: 55px;
        font-style: normal;
        font-weight: 400;
        line-height: 31px;
      }
    }
  }
}
/deep/.el-form-item {
  .el-form-item__content {
    .el-button {
      background: #00ddce;
      border-radius: 28.1313px;
      width: 438.23px;
      height: 40px;
      color: #0a2228;
      font-weight: 600;
    }
  }
}

/deep/.el-button {
  border-radius: 25px;
  width: 161.83px;
  height: 40px;
}
.notice {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 31px;
  /* or 221% */
  letter-spacing: 0.02em;
  margin: -10px 0 25px;
  color: #7f9399;
}
